import React, {Component} from 'react';
import Arrow from '../images/arrow.svg'
import Arrowl from '../images/arrowl.svg'
import {useSpring, animated} from 'react-spring'

class Cursor extends Component {

    state = {
        left: 0,
        top: 0,
        display: 'none',
        transform: 'scaleX(1)',
        cursor: 'none',
        currentStep: -1,
        stateCursor: 1
    }

    componentDidMount() {
        if(this.props.cursorReady) {
            this.props.cursorReady({init: () => this.init()})
        }
    }

    componentWillUnmount() {
        let target = document.querySelector('.content');
        document.removeEventListener('mousemove', this.onMouseMove, true);
        target.removeEventListener('click', this.onClick, true);
    }

    init() {
        this.setState({currentStep: 0});
        
        let target = document.querySelector('.content');

        this.onMouseMove = e => {
            if(document.getElementById('nav-desktop').querySelector('input').checked) {
                return;
            }
            this.setState({ left: e.pageX - 20, top: e.pageY - 60, display: 'block', transform: 'scaleX(1)', cursor: 'none', stateCursor: 1 });
            document.body.classList.add('hideCursor');
            if(e.pageX <= target.offsetLeft - (window.innerWidth / 10) || e.pageX >= target.offsetLeft + target.offsetWidth + (window.innerWidth / 10) ) {
                this.setState({display: 'none'});
                document.body.classList.remove('hideCursor');
            }

            if(e.pageY <= target.offsetTop || e.pageY >= target.offsetTop + target.offsetHeight) {
                this.setState({display: 'none'});
                document.body.classList.remove('hideCursor');
            }

            if(e.pageX <= target.offsetLeft + (target.offsetWidth / 2)) {
                this.setState({transform: 'scaleX(-1)', stateCursor: -1});
            } 
        };

        document.addEventListener('mousemove', this.onMouseMove, true);

        this.onClick = e => {
            if(this.state.stateCursor === -1 && this.state.currentStep > 0) {
                this.animate();
            } 
            
            if(this.state.stateCursor === 1 && this.state.currentStep < this.props.children.length - 1) {
                this.animate();
            }
        };

        target.addEventListener('click', this.onClick, true);
    }

    animate() {
        this.setState({currentStep: this.state.currentStep + this.state.stateCursor});        
    }
    
    render() {
        const step = React.Children.map(this.props.children, (child, index) => {
            if(index === this.state.currentStep) {
                return child;
            }
        });

        if(typeof window !== 'undefined' && window.getWidth() > 760 ) {
            return (
                <>
                    <div className="cursor-desktop" style={{
                              left: this.state.left,
                              top: this.state.top,
                              display: this.state.display,
                              transform: this.state.transform,
                            }}><Arrow/></div>
                    <div className="cursor">{step}</div>
                </>
            )
        }

        if(typeof window !== 'undefined' && window.getWidth() <= 760 ) {
            return (
                <div style={{width: `94%`}}>
                    {step}
                    <div style={{ display: `flex`, flexDirection: `row`, marginTop: `30px`}}>
                        <div style={{height: `24px`}} className={this.state.currentStep >= this.props.children.length - 1 ? 'hide': ''}><Arrow/></div>
                    </div>
                </div>
            )
        }

        return (<></>)
    }
}

function CursorStep({content, index}) {
    const animation = useSpring({config: {duration: 1500}, opacity: 1, from: {opacity: 0}});
    return (
        <animated.div className={`step ${'step-' + index}`} style={animation}>
            <h2 dangerouslySetInnerHTML={{__html: content.h2}}></h2>
            <div dangerouslySetInnerHTML={{__html: content.div}}></div>
        </animated.div>
    )
}

export {Cursor, CursorStep}